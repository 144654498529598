<template>
	<v-card :loading="loading && 'deep-purple'" class="mx-auto my-12 rounded-lg login-card" max-width="400" outlined>
		<!-- <template slot="progress">
      <v-progress-liner color="deep-purple" height="10" indeterminate> </v-progress-liner>
    </template> -->
		<v-card-title>Login</v-card-title>
		<!-- insert banner here for errors and a way to clear them -->

		<v-banner single-line transition="slide-y-transition" v-if="errorMessage" color="pink lighten-4">
			<!-- <div>
			<p class="login-error-message" @click="resetLoginError(loginError)" >{{ loginError }}</p>
		<v-icon dark color="white" right>mdi-cancel</v-icon>
		</div> -->
			<v-chip close color="pink" dark @click:close="clearErrorMessage" v-if="errorMessage.length > 0">{{
				errorMessage
			}}</v-chip>
		</v-banner>

		<template>
			<validation-observer ref="observer" v-slot="{ invalid }">
				<form @submit.prevent="handleLogin">
					<validation-provider v-slot="{ errors }" name="email" rules="required|email">
						<v-text-field
							v-model="user.email"
							:error-messages="errors"
							label="E-mail"
							required
							type="email"
						></v-text-field>
					</validation-provider>

					<validation-provider v-slot="{ errors }" name="password" rules="required">
						<v-text-field
							v-model="user.password"
							:error-messages="errors"
							label="Password"
							required
							type="password"
						></v-text-field>
					</validation-provider>

					<div class="form-buttons">
						<v-btn class="mr-5 deep-purple white--text" type="submit" :disabled="invalid" :loading="loadingUser">
							Login
						</v-btn>
						<v-btn @click="clear"> clear </v-btn>
					</div>
				</form>
			</validation-observer>
			<div class="link-to-signup"><a @click="signupPage">Don't have an account? Click here to sign up</a></div>
		</template>
	</v-card>
</template>

<script>
/* eslint-disable */
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import { mapGetters } from "vuex";
import User from "../models/user";

setInteractionMode("eager");

extend("digits", {
	...digits,
	message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
	...required,
	message: "{_field_} can not be empty",
});

extend("max", {
	...max,
	message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email must be valid",
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			user: new User("", ""),
			loading: false,
			errorMessage: "",
		};
	},
	computed: {
		...mapGetters({
			signedUpUser: "auth/getUserEmail",
			signedUpPassword: "auth/getUserPassword",
			loadingUser: "auth/getLoadingUser",
		}),

		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},

		// learning about refs https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/Vue_refs_focus_management

	created() {
		if (this.loggedIn) {
			this.$router.push("/");
		}
		this.user.email = this.signedUpUser !== null ? this.signedUpUser : "";
		this.user.password = this.signedUpPassword !== null ? this.signedUpPassword : "";
	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value.password.length > 0) {
					this.$refs.observer.validate();
				}
			},
		},
	},
	methods: {
		async handleLogin() {
			this.loading = true;
			const isValid = await this.$refs.observer.validate();
			if (!isValid) {
				this.loading = false;
				return;
			}
			if (this.user.email && this.user.password) {
				this.$store.dispatch("auth/login", this.user).then(
					() => {
						this.$router.push("/");
					},
					(error) => {
						this.loading = false;
						this.errorMessage = error.toString() || error.response.data.error || error.response.data.general;
						// fix the error message
						console.log("error messaage", this.errorMessage);
					}
				);
			}
		},
		clearErrorMessage() {
			this.errorMessage = null;
		},
		clear() {
			this.user.email = "";
			this.user.password = "";
			this.errorMessage = null;
			this.$refs.observer.reset();
		},
		hello() {
			this.$router.push("/hello");
		},
		signupPage() {
			this.$router.push("/signup");
		},
	},
};
// https://vee-validate.logaretm.com/v3/advanced/cross-field-validation.html#targeting-other-fields
</script>

<style lang="scss">
.login-card {
	padding: 2%;
}
.login-error-message {
	color: white;
}
.form-buttons {
	padding-top: 1.5vh;
}
.link-to-signup {
	height: 10vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
